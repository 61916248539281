<template>
  <div
    v-if="!submissionCompleted"
    class="lg:pb-1 md:pb-1 pb-4 flex w-full flex-col gap-4 px-5"
  >
    <div class="flex items-center justify-between">
      <div
        class="cursor-pointer px-3 py-1 flex items-center gap-1 text-primary"
        @click="$router.go(-1)"
      >
        <vs-icon size="20px" icon="arrow_back"></vs-icon>
        <p class="lg:text-lg md:text-lg text-base">Back</p>
      </div>
      <div class="flex items-center gap-3 lg:w-1/5 md:w-1/3 w-1/2">
        <vs-button
          class="w-full px-2 lg:text-base md:text-base text-sm"
          type="border"
          @click="handlePreview"
          :disabled="isPreviewLoading"
        >
          <div class="flex items-center gap-2">
            <vs-icon icon="refresh" class="animate-spin" v-if="isPreviewLoading"></vs-icon>
            <p>Preview</p>
          </div>
        </vs-button>
        <vs-button
          @click="saveData"
          :disabled="isCreatingOrUpdating"
          class="py-3 w-full"
        >
          <div
            class="flex items-center justify-center gap-1 lg:text-base md:text-base text-sm"
          >
            <vs-icon
              v-if="isCreatingOrUpdating"
              icon="refresh"
              class="animate-spin"
            ></vs-icon>
            {{ btnLabel }}
          </div>
        </vs-button>
      </div>
    </div>
    <div class="w-full">
      <p class="pl-3 lg:text-3xl md:text-2xl text-xl font-bold">
        Aftercare Document
      </p>
      <vs-input
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first('basic.aftercare_form') ? true : false"
        v-validate="'required'"
        name="aftercare_form"
        data-vv-as="Aftercare Form Name"
        data-vv-scope="basic"
        :danger-text="errors.first('basic.aftercare_form')"
        placeholder="Aftercare document name"
        v-model="aftercareDetails.name"
        class="lg:w-1/3 md:w-1/3 w-full px-2 lg:text-base md:text-base text-sm"
        label="Aftercare document name"
      />
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      isCreatingOrUpdating: false,
      aftercareDetails: {},
    };
  },
  methods: {
    async saveData() {
      const isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate();
      if (isValid) {
        this.$store.dispatch("aftercare/updateAftercareDetails", this.aftercareDetails);
        this.$store.dispatch("aftercare/updateFormSubmission", true);
      }
    },
    handlePreview() {
      this.$store.dispatch("aftercare/setPreviewLoading", true);
    }
  },
  computed: {
    btnLabel() {
      if (this.$route.name.search("Create") !== -1) return "Save";
      return "Update";
    },
    aftercareDocumentName() {
      return this.$store.getters["aftercare/getAftercareDetails"];
    },
    isSubmitting() {
      return this.$store.getters["aftercare/isFormSubmitting"];
    },
    submissionCompleted() {
      return this.$store.getters["aftercare/isSubmissionCompleted"];
    },
    isPreviewLoading() {
      return this.$store.getters["aftercare/isPreviewLoading"];
    },
  },
  watch: {
    isSubmitting: {
      handler(val) {
        this.isCreatingOrUpdating = val;
      },
    },
    aftercareDocumentName: {
      handler(val) {
        this.aftercareDetails = { ...val };
      },
      immediate: true,
    },
  },
};
</script>
  <style>
.save-btn {
  margin-top: 20px;
}
</style>